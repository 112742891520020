/* syne-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Syne";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/syne-v22-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* syne-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Syne";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/syne-v22-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* syne-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Syne";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/syne-v22-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* syne-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Syne";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/syne-v22-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* syne-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Syne";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/syne-v22-latin-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* overpass-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/overpass-v13-latin-100.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-100italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: italic;
  font-weight: 100;
  src: url("./assets/fonts/overpass-v13-latin-100italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/overpass-v13-latin-200.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: italic;
  font-weight: 200;
  src: url("./assets/fonts/overpass-v13-latin-200italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/overpass-v13-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: italic;
  font-weight: 300;
  src: url("./assets/fonts/overpass-v13-latin-300italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/overpass-v13-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/overpass-v13-latin-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/overpass-v13-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/overpass-v13-latin-500italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/overpass-v13-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: italic;
  font-weight: 600;
  src: url("./assets/fonts/overpass-v13-latin-600italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/overpass-v13-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/overpass-v13-latin-700italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/overpass-v13-latin-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: italic;
  font-weight: 800;
  src: url("./assets/fonts/overpass-v13-latin-800italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/overpass-v13-latin-900.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* overpass-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Overpass";
  font-style: italic;
  font-weight: 900;
  src: url("./assets/fonts/overpass-v13-latin-900italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

$primaryFont: #fffcf3;
$secondaryFont: #00bc8f;

@mixin flex($fd: row, $al: center, $jc: center) {
  display: flex;
  align-items: $al;
  justify-content: $jc;
  flex-direction: $fd;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  box-sizing: border-box;
}

@mixin section() {
  padding: 30px 100px;
  background-color: $primaryFont;

  @media (max-width: 900px) {
    padding: 30px 25px;
  }
}

@mixin button() {
  margin: 64px;
  padding: 20px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 4px solid #000;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15);
  font-family: Overpass;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 27.6px */
  white-space: nowrap;
  display: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;

  @media screen and (max-width: 900px) {
    display: inline-flex !important;
  }

  &:hover{
    font-weight: 800;
    transform: scaleX(1.2);
  }

  &:active{
    background-color: black;
    color: white;
  }
}

@mixin buttonMob() {
  margin: 64px;
  padding: 20px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 4px solid #000;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15);
  font-family: Overpass;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 27.6px */
  white-space: nowrap;
  display: inline-flex;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;

  &:hover{
    font-weight: 800;
    transform: scaleX(1.2);
  }

  &:active{
    background-color: black;
    color: white;
  }

  @media screen and (max-width: 900px) {
    display: none !important;
  }
}

a {
  text-decoration: none;
  color: #000;
  font-family: Overpass;
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 23px */
  letter-spacing: -0.575px;
}

.w-100{
  width: 100% !important;
}
